import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Google tag (gtag.js) code
const script1 = document.createElement('script');
script1.src = "https://www.googletagmanager.com/gtag/js?id=G-7DCNH7KT1T";
script1.async = true;
document.head.appendChild(script1);

script1.onload = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-7DCNH7KT1T');
};

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();