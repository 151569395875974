import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MegaMenu from "./mega-menu/MegaMenu";
import MegaMenuMobile from "./mega-menu/MegaMenuMobile";

const HeaderFour = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  // 用 useEffect 添加Google Analytics代码
  useEffect(() => {
    // Google tag (gtag.js)
    const script1 = document.createElement('script');
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-7DCNH7KT1T"; // 替换为您的实际跟踪ID
    script1.async = true;
    document.head.appendChild(script1);

    script1.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-7DCNH7KT1T'); // 替换为您的实际跟踪ID
    };

    // 清理函数
    return () => {
      document.head.removeChild(script1);
    };
  }, []); // 空依赖数组表示只在组件挂载时执行

  // 监听滚动事件
  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    
    // 清理函数，移除事件监听器
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-four bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-four bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <img src="images/logo/ll_05.png" alt="brand logo" />
            </Link>
          </div>
          {/* End Logo */}

          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div
                className="navbar-collapse collapse"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex align-items-center">
                  <MegaMenu />
                  {/* End MegaMenu */}

                  <ul className="right-button-group d-flex align-items-center justify-content-center">
                    <li>
                      <a href="https://account.lightlayer.net" className="signIn-action">
                        Login
                      </a>
                    </li>
                    <li>
                      <a href="https://account.lightlayer.net/index.php?/signup/" className="theme-btn-five">
                        SIGN UP
                      </a>
                    </li>
                  </ul>
                  {/* End right-button-group  */}
                </div>
              </div>
            </div>
          </nav>
          {/* End nav */}
        </div>

        <MegaMenuMobile />

        {/* 	End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default HeaderFour;